import Api from '@/services/Index';

const getAll = async (query) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests/airport-transfer/list', query);
}
const airportExcelExport = async (query) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests/airport-transfer/excel-export', {responseType:'arraybuffer',query});
}


export default {
    getAll,
    airportExcelExport
}
