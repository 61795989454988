<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('airport_transfer_request')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('airport_transfer_request')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear" :exportExcel="checkPermission('housingrequest_airporttransferexcelexport')" @exportExcel="exportExcel"  >
                <b-row>
                    <b-col cols="6" md="6">
                        <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                            <v-date-picker v-model="datatable.queryParams.filter.date"
                                           is-range
                                           :locale="'en'"
                                           :model-config="{type: 'string', mask: 'MM/DD/YYYY'}"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
    // Components
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    // Others
    import qs from 'qs'
    import HousingTransferRequest from "@/services/HousingTransferRequest";
    import AsalService from "@/services/AsalService";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            DatatableFilter,
            Datatable,
        },
        metaInfo() {
            return {
                title: this.$t('airport_transfer_request'),
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: this.toUpperCase('id'),
                            field: 'id',
                            hidden: true
                        },
                        {
                            label: this.toUpperCase('name'),
                            field: 'name',
                            hidden: false,
                            formatFn: (value, row) => {
                                return row.name + ' ' + row.surname;
                            }
                        },
                        {
                            label: this.toUpperCase('email'),
                            field: 'email',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('gender'),
                            field: 'gender',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('phone'),
                            field: 'mobile_tel',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('airline_name'),
                            field: 'airline_name',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('arrival_date'),
                            field: 'arrival_date',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('flight_code'),
                            field: 'flight_code',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('accompany_name_surname'),
                            field: 'accompany_name_surname',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('accompany_email'),
                            field: 'accompany_email',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('accompany_phone'),
                            field: 'accompany_phone',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('status'),
                            field: 'status',
                            hidden: false,
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                    }
                },
                formProcess: null,
                formId: null,
                form: {}
            }
        },
        mounted() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {}
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;
                let date
                if (this.datatable.queryParams.filter.date?.start && this.datatable.queryParams.filter.date?.end) {
                    date = this.datatable.queryParams.filter.date
                    this.datatable.queryParams.filter.between_dates=this.datatable.queryParams.filter.date?.start  +','+this.datatable.queryParams.filter.date?.end
                    delete this.datatable.queryParams.filter.date
                }
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                return HousingTransferRequest.getAll(config)
                                     .then((response) => {
                                         this.datatable.rows = response.data.data
                                         this.datatable.total = response.data.pagination.total
                                     })
                                     .finally(() => {
                                         this.datatable.isLoading = false;
                                         this.datatable.queryParams.filter.date =date
                                     });
            },
            formClear() {
                this.formId = null
                this.formProcess = null
            },
            exportExcel(){
                let date
                if (this.datatable.queryParams.filter.date?.start && this.datatable.queryParams.filter.date?.end) {
                    date = this.datatable.queryParams.filter.date
                    this.datatable.queryParams.filter.between_dates=this.datatable.queryParams.filter.date?.start  +','+this.datatable.queryParams.filter.date?.end
                    delete this.datatable.queryParams.filter.date
                }
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                return HousingTransferRequest.airportExcelExport(config)
                    .then((response) => {
                        this._downloadFile(response,this.$t('airport-transfer-request')+'.xlsx')
                    }).catch(err=>this.showErrors(err)).finally(this.datatable.queryParams.filter.date =date)
            }
        }
    }
</script>

